angular.module('huni').controller('TopLevelController', [
    '$scope', 'EmbeddedMode', 'MyHuniMenuItems', 'UserMgtService',
function ($scope, EmbeddedMode, menuItems, userMgtService) {
    $scope.activeSnapIn = undefined;
    $scope.menuItems = menuItems;

    $scope.$on('SnapInStateChangedEvent', function(ev, snapIn) {
        $scope.activeSnapIn = snapIn.isActive ? snapIn : undefined;
        if (snapIn.isActive) {
            $scope.activeSnapIn = snapIn;
        }
        else {
            $scope.activeSnapIn = undefined;
        }
    });

    $scope.setEmbeddedMode = function() {
        EmbeddedMode.set(true);
    };

    // snapInHeight is used to work out the size of the snap-in, and we watch
    // that function to detect any changes, and adjust the mainview as needed
    var snapIns = [ 'link-snapin', 'collection-snapin', 'embed-snapin' ];
    function snapInHeight() {
        var totalHeight = 0;
        _.each(snapIns, function(id) {
            var element = document.getElementById(id);
            if (element) {
                totalHeight += element.offsetHeight;
            }
        });
        return totalHeight;
    }

    var mainView = document.getElementById('main-view');
    $scope.$watch(snapInHeight, function(newValue, oldValue) {
        mainView.style.marginTop = newValue + 'px';
    });

    $scope.hideMenu = function () {
        $scope.isCollapsed = true;
    };

    function init() {
        userMgtService.loginStatus().then(function (data) {
            $scope.user = data;
        });
    }

    init();
}]);
