angular.module('huni').factory('EmbeddedMode',
function() {

    let embeddedMode = false;

    // Whole service is just an accessor for the embeddedMode flag.
    //   const embeddedMode = EmbeddedMode.get();
    //   EmbeddedMode.set(embeddedMode);
    return {
        get: ()      => embeddedMode,
        set: (value) => { embeddedMode = value },
    };
});
