angular.module('huni').directive('record', ['EmbeddedMode',
function(EmbeddedMode) {
    function link(scope) {
        scope.embedded = EmbeddedMode.get();

        scope.$on('CollectionChangedEvent', function(event, data) {
            if (data.record.docid !== scope.record.docid) {
                return; // not this record
            }

            var collection = data.collection;
            if (data.added) {
                scope.record.collections[collection.id] = collection;
            }
            else {
                delete scope.record.collections[collection.id];
            }
        });

        function swap(obj, fieldA, fieldB) {
            var t = obj[fieldA];
            obj[fieldA] = obj[fieldB];
            obj[fieldB] = t;
            return obj;
        }

        scope.$on('LinkChangedEvent', function(event, data) {
            var newLink;
            if (data.link.from_docid === scope.record.docid) {
                // This record is the 'from' doc in this link.
                newLink = data.link;
            }
            else if (data.link.to_docid === scope.record.docid) {
                // This record is the 'to' doc in this link.
                // We need to reverse the link.
                newLink = _.clone(data.link);

                swap(newLink, 'to_docid',    'from_docid');
                swap(newLink, 'linktype_id', 'pairtype_id');
                swap(newLink, 'linktype',    'pairtype');
            }
            else {
                // Not this record.
                return;
            }

            if (data.action === 'created') {
                if (_.has(scope.record, 'links')) {
                    scope.record.links.push(newLink);
                }
                if (_.has(scope.record, 'linkCount')) {
                    scope.record.linkCount++;
                }
            }
            else if (data.action === 'deleted') {
                if (_.has(scope.record, 'links')) {
                    scope.record.links =
                        _.reject(scope.record.links, function(test) {
                            return test.id === newLink.id;
                        });
                }
                if (_.has(scope.record, 'linkCount')) {
                    scope.record.linkCount--;
                }
            }
            else {
                var oldLink = _.find(scope.record.links, function(test) {
                    return test.id === newLink.id;
                });
                if (oldLink) {
                    _.extend(oldLink, newLink);
                }
            }
        });

        scope.isCollection = function() {
            return scope.record.entityType === 'Collection'
                || scope.record.sourceAgencyCode === 'HuNI';
        };

        scope.hasCollections = function(record) {
            return !_.isEmpty(record.collections);
        };

        scope.hasLinks = function(record) {
            return !_.isEmpty(record.links);
        };

        scope.sortedCollections = function(record) {
            return _.sortBy(record.collections, function(coll) {
                return coll.name.toLowerCase();
            });
        };

        scope.collectionUri = function(collection) {
            // We don't need to know the relationship between the user and
            // the collection. We assume that if they can see it, then either
            // it's public, or the user has some role on that collection.
            // If the collection is public, send them to the public link,
            // otherwise the private one. If they don't have access to the
            // private one the backend will stop them.
            const prefix = collection.is_public ? '#' : '#/myhuni';
            return `${ prefix }/collection/${ collection.id }`;
        };

        scope.selectLink = function(docid) {
            scope.$emit('RecordSelectedEvent', docid);
        };

        scope.viewLink = function(link) {
            scope.$emit('ViewLinkEvent', link);
        }

        scope.highlightLink = function(link, isHighlighted) {
            scope.$emit('HighlightLinkEvent', link, isHighlighted);
        }

        // We want to only list maxMentions of these, with an extra line
        // underneath saying "and 3 more".
        // But if we would be saying "and 1 more" we might as well just show it.
        scope.maxMentions = 3;
        scope.mentionsToShow = function(record) {
            if (! record.mentions) {
                return 0;
            }
            if (!scope.brief) {
                return record.mentions.length;
            }
            // We add 1 in the test to avoid the "and 1 more" case
            if (record.mentions.length > scope.maxMentions + 1) {
                return scope.maxMentions;
            }
            else {
                return record.mentions.length;
            }
        };

        scope.mentionLink = function(mention) {
            // mentions are like: name: "... context ..."
            var rest = mention.indexOf(': "...');
            var name = mention.substring(0, rest);
            var context = mention.substring(rest);

            return '<a href="#/results?q=' + encodeURI(name) + '">'
                   + name + '</a>' + context;
        }
    }

    return {
        restrict: 'E',
        scope: {
            record:     '=',
            snapIn:     '=',
            brief:      '@',
            user:       '=',
        },
        templateUrl: 'partials/directives/record.html',
        link: link,
    };
}]);
