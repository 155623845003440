angular.module('huni').directive('embedSnapin', [
    '$rootScope', '$location',
function($rootScope, $location) {

    function link(scope, element, attrs) {

        scope.isActive = false;
        scope.name = 'embed';

        scope.embedCode = function() {
            const baseURL = location.origin;
            let entityType = scope.record.entityType;
            return `<iframe src="${baseURL}/embed#/record/${scope.record.docid}"
    title="${entityType.toUpperCase()}: ${scope.record.displayName}"
    height="200" width="300">
</iframe>`
        };

        scope.copyToClipboard = function() {
            var copyText = scope.embedCode();
            navigator.clipboard.writeText(copyText);
        };

        function setActive(isActive) {
            if (scope.isActive !== isActive) {
                scope.isActive = isActive;
                $rootScope.$broadcast('SnapInStateChangedEvent', scope);
            }
        };

        scope.close = function() {
            setActive(false);
        };

        $rootScope.$on('EmbedEvent', function(ev, record) {
            scope.record = record;
            setActive(true);
        });
    }

    return {
        restrict: 'E',
        scope: {
        },
        templateUrl: 'partials/snapins/embed.html',
        link: link,
    };
}]);
