angular.module('huni').directive('embedButton',
    function() {
    
        function link(scope, element, attrs) {
    
            function refreshState() {
                if (!scope.snapIn) {
                    // No snapin active
                    scope.isEnabled = true;
                }
                else {
                    scope.isEnabled = false;
                }
            }
    
            scope.$on('EmbedSnapInChangedEvent', refreshState);
            scope.$watch('snapIn', refreshState);
            refreshState();
        }
    
        return {
            restrict: 'E',
            scope: {
                record: '=',
                snapIn: '=',
            },
            templateUrl: 'partials/buttons/embed.html',
            link: link,
        };
    }
    );
    